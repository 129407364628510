// @ts-nocheck
/* eslint-enable */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { stacked } from 'app/shared/styles/_spacing';
import { colors } from 'app/shared/styles/_colors';
import Linker from 'app/shared/modules/Linker';
import PromptBreadcrumb from 'app/shared/modules/auth/PromptBreadcrumb';
import Section from 'app/shared/modules/Section';
import Title from 'app/shared/modules/Title';

const StyledTitle = styled(Title)`
  ${stacked._2x};
  color: ${colors['$hpx-blue-600']};
`;
const StyledDarkSection = styled(Section)`
  color: ${colors['$hpx-grey-600']};
`;
const AccountDeactivated = ({ showTitle = true, onBackClick }) => {
  return (
    <Fragment>
      <PromptBreadcrumb onBackClick={onBackClick} />
      <StyledDarkSection>
        {showTitle && <StyledTitle size="lg">Uh oh...</StyledTitle>}
        We have received a request to delete the account associated with this email address and are currently in the
        process of doing so. <br />
        <br />
        Please use another email address and try again. <br />
        <br />
        For additional help, please reach out to us on our{' '}
        <Linker to="https://help.hotpads.com/hc/en-us/requests/new?ticket_form_id=360000344072">help page</Linker>.
      </StyledDarkSection>
    </Fragment>
  );
};

export default AccountDeactivated;
