// @ts-nocheck
/* eslint-enable */
/**
 * Official implementation details:
 * https://developer.apple.com/documentation/signinwithapplejs/configuring_your_webpage_for_sign_in_with_apple
 *
 * Additional implementation details needed for using a custom button:
 * https://github.com/Techofficer/node-apple-signin
 */

const AUTH_ENDPOINT = 'https://appleid.apple.com/auth/authorize';
const AUTH_OPTIONS = {
  clientId: 'com.hotpads.hotpadsweb',
  scope: 'name email',
  redirectURI: 'https://hotpads.com/apple-auth',
  state: `${Math.random().toString(36).substr(2, 5)}`,
};

const appleAuth = {
  getAuthorizationUrl() {
    const queryParams = [];

    queryParams.push(`response_type=code`);
    queryParams.push(`state=${AUTH_OPTIONS.state || 'state'}`);
    queryParams.push(`client_id=${AUTH_OPTIONS.clientId}`);
    queryParams.push(`redirect_uri=${AUTH_OPTIONS.redirectURI}`);
    queryParams.push(`response_mode=form_post`);

    if (AUTH_OPTIONS.scope) {
      queryParams.push(`scope=openid ${AUTH_OPTIONS.scope}`);
    } else {
      queryParams.push(`scope=openid`);
    }

    return `${AUTH_ENDPOINT}?${queryParams.join('&')}`;
  },
};

export default appleAuth;
