// @ts-nocheck
/* eslint-enable */
const controller = {
  getHeaderCaption({ modalCaption, queryStr = '', isCcpaRedirectUrl }) {
    const queryObj = this.getQueryObjFromUrlStr(queryStr);
    let suffix;

    if (queryObj.redirect) {
      if (queryObj.redirect.includes('/renter-hub/')) {
        suffix = queryObj.redirect.split('/renter-hub/')[1];
        if (suffix.startsWith('applications')) {
          return 'Sign in or register to manage your rental applications.';
        } else if (suffix.startsWith('payments')) {
          return 'Sign in or register to manage your rental payments.';
        }
      } else if (queryObj.redirect.includes('/rental-manager/')) {
        if (queryObj.postingPath) {
          return 'Sign in or register to post a listing.';
        }
        return 'Sign in or register to manage your rental properties.';
      }
    } else if (isCcpaRedirectUrl) {
      return `Enter the email address of the account you'd like to manage.`;
    } else if (modalCaption) {
      return modalCaption;
    }

    return 'Sign in or register to keep track of your favorite listings and more!';
  },
  getQueryObjFromUrlStr(queryStr) {
    if (!queryStr) {
      return {};
    }
    const str = queryStr[0] === '?' ? queryStr.substr(1) : queryStr;
    const queryObj = {};
    let item;
    str.split('&').forEach((part) => {
      item = part.split('=');
      queryObj[item[0]] = decodeURIComponent(item[1]);
    });
    return queryObj;
  },
};

export default controller;
