// @ts-nocheck
/* eslint-enable */
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import mailcheck from 'mailcheck';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { stacked } from 'app/shared/styles/_spacing';
import { colors } from 'app/shared/styles/_colors';
import { yieldCallback } from '@zillow/yield-callback';

// Actions
import AppActions from 'app/shared/flux/actions/AppActions';
import AuthActions from 'app/shared/flux/actions/AuthActions';
import ErrorActions from 'app/shared/flux/actions/ErrorActions';

// Components
import AccountDeactivated from 'app/shared/modules/auth/AccountDeactivated';
import Button from 'app/shared/core/Button';
import Center from 'app/shared/modules/Center';
import Divider from 'app/shared/modules/Divider';
import Input from 'app/shared/core/Input';
import Linker from 'app/shared/modules/Linker';
import Row from 'app/shared/core/Row';
import Section from 'app/shared/modules/Section';
import Text from 'app/shared/core/Text';
import Title from 'app/shared/modules/Title';

// Misc / Utils
import './style.scss';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import appleAuth from 'app/client/appleAuth';
import browserUtils from 'app/client/utils/browserUtils';
import controller from './controller';
import IconApple from 'images/icons/apple_login.svg';
import IconFacebook from 'images/icons/facebook_login.svg';
import IconGoogle from 'images/icons/google_login.svg';
import IconLock from 'images/icons/lock.svg';
import loginUtils from 'app/shared/utils/loginUtils';
import pathsManifest from 'app/shared/utils/pathsManifest';
import queryUtils from 'app/shared/utils/queryUtils';
import userUtils from 'app/shared/utils/userUtils';
import validator from 'app/shared/utils/validator';
import STRING_CONSTANTS from 'app/shared/constants/StringConstants';
import { TrackFacebookLogin } from 'app/shared/models/Clickstream/LoginClickstreamEvents';
import { getGlobalLogger } from '@zg-rentals/logger-base';

const logger = getGlobalLogger('modules/auth');

const FREEPASS_LOGIN_ERROR_MESSAGES = {
  default: STRING_CONSTANTS.FREEPASS_DEFAULT,
};
const FACEBOOK_LOGIN_ERROR_MESSAGES = {
  default: STRING_CONSTANTS.FACEBOOK_DEFAULT,
};
const StyledTitle = styled(Title)`
  ${stacked._2x};
`;
const StyledDarkText = styled(Text)`
  color: ${colors['$hpx-grey-600']};
`;
const StyledAlertText = styled(Text)`
  color: ${colors['$hpx-red-400']};
`;
class PromptEmail extends Component {
  static propTypes = {
    email: PropTypes.string,
    header: PropTypes.elementType,
    handleLoginSuccess: PropTypes.func,
    makeVisible: PropTypes.func,
    setEmail: PropTypes.func,
    showDescription: PropTypes.bool,
    showTitle: PropTypes.bool,
    title: PropTypes.string,
  };

  static defaultProps = {
    email: '',
    header: null,
    handleLoginSuccess: () => {},
    makeVisible: () => {},
    setEmail: () => {},
    showTitle: true,
    showDescription: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      emailAddress: this.props.email || '',
      errorMsg: null,
      loading: false,
      mailCheckSuggestion: '',
      showAccountDeactivated: false,
    };
  }

  componentDidMount() {
    const { isInitialSsrPage } = this.props;

    /**
     * Facebook and Google login must be initialized after has OneTrust initialized, which occurs
     * asynchronously. On SSR, instead of loading Facebook and Google auth in componentDidMount,
     * wait for OneTrust to intialize. The below listener listens for a oneTrustLoadedEvent
     * event that is dispatched in `app/client/oneTrust/oneTrustOnInit.js`
     */
    if (isInitialSsrPage) {
      window.addEventListener('oneTrustLoadedEvent', this.initFacebookAndGoogleApis);
    } else {
      this.initFacebookAndGoogleApis();
    }
  }

  initFacebookAndGoogleApis = () => {
    const { dispatch } = this.props;
    dispatch(AppActions.loadFacebookApi());
    dispatch(AppActions.loadSignInWithGoogleApi({ onError: this.handleSetErrorMsg }));
  };

  handleSetErrorMsg = (errorMsg) => {
    this.setState({ errorMsg });
  };

  handleFbLogin = () => {
    const { dispatch, handleLoginSuccess } = this.props;

    this.setState({
      loading: true,
    });

    dispatch(AuthActions.fb.validate())
      .then((result) => {
        if (result.error) {
          this.setState({
            loading: false,
            errorMsg: FACEBOOK_LOGIN_ERROR_MESSAGES.default,
          });
        } else if (result.status === 'ACCOUNT_DEACTIVATED') {
          this.setState({
            loading: false,
            showAccountDeactivated: true,
          });
        } else {
          this.setState({
            loading: false,
          });
          handleLoginSuccess();
        }
        dispatch(AuthActions.login.checkLoginPageRedirect());
        userUtils.successLoginRedirect();
        return true;
      })
      .catch((err) => {
        const { message } = err;

        this.setState({
          loading: false,
          errorMsg: message || FACEBOOK_LOGIN_ERROR_MESSAGES.default,
        });
        dispatch(
          ErrorActions.errorHandler({
            error: err,
            errorLocation: 'component.promptEmail.handleFbLogin',
            errClass: 'authActions',
          }),
        );
      });

    dispatch(analyticsEvent(gaEvents.LOGIN_FACEBOOK, { newLaneEvent: TrackFacebookLogin() }));
  };

  yieldHandleSubmitEmail = yieldCallback(() => {
    const { dispatch } = this.props;
    const { emailAddress, mailCheckSuggestion } = this.state;

    this.checkEmailDomain(emailAddress).then((valid) => {
      /**
       * HP-5546: Check email spelling on submit.
       * The first time a user tries to submit an email address we believe
       * is incorrect, we set a warning message on mailCheckSuggestion.
       * If the email spelling is correct, then we should ignore any warnings
       * and continue to login. (e.g., valid === false and mailCheckSuggestion == true)
       */
      if (!valid && !mailCheckSuggestion) {
        return;
      }

      if (validator.email(emailAddress).isValid) {
        this.freepassLogin(emailAddress);
        dispatch(analyticsEvent(gaEvents.LOGIN_FREEPASS));
      } else {
        this.setState({
          errorMsg: validator.email(emailAddress).message,
        });
      }
    });
  });

  handleSubmitEmail = (e) => {
    e.preventDefault();

    this.yieldHandleSubmitEmail();
  };

  checkEmailDomain = (email) => {
    return new Promise((resolve) => {
      mailcheck.run({
        email,
        suggested: (suggestion) => {
          this.setState({ mailCheckSuggestion: suggestion.full });
          return resolve(false);
        },
        empty: () => {
          this.setState({ mailCheckSuggestion: '' });
          return resolve(true);
        },
      });
    });
  };

  freepassLogin = (email) => {
    const { setEmail, makeVisible, dispatch, countryCode, isCcpaRedirectUrl, handleLoginSuccess } = this.props;
    const { emailAddress } = this.state;

    this.setState({
      loading: true,
    });

    dispatch(AuthActions.login.checkCcpaAndLogin({ email, isCcpaRedirectUrl }))
      .then((data = {}) => {
        if (data.data && data.data.forwardUrl) {
          window.location.assign(data.data.forwardUrl);
          return data;
        }
        if (data.success && (!data.loggedIn || !data.creds.verified)) {
          setEmail(emailAddress);
          makeVisible('promptEmailSent');
        } else if (data.status === 'USER_ALREADY_EXISTS' && !data.loggedIn) {
          setEmail(emailAddress);
          makeVisible('promptPassword');
        } else if (data.status === 'ACCOUNT_DEACTIVATED') {
          this.setState({
            loading: false,
            showAccountDeactivated: true,
          });
        } else if (data.status === 'EMAIL_NOT_SENT') {
          logger?.warn('PromptEmail#freepassLogin Error:', data.message);
          this.setState({
            errorMsg: FREEPASS_LOGIN_ERROR_MESSAGES.default,
            loading: false,
          });
        } else if (loginUtils.isCanadianUser(countryCode, email)) {
          // add logic to display CASL requirement
          setEmail(emailAddress);
          makeVisible('promptCasl');
        } else {
          handleLoginSuccess();
          userUtils.successLoginRedirect();
        }
        return data;
      })
      .catch((err) => {
        this.setState({
          errorMsg: err || FREEPASS_LOGIN_ERROR_MESSAGES.default,
          loading: false,
        });
      });
  };

  handleEmailInputBlur = (e) => {
    const emailInput = e.target.value;
    this.setState({ emailAddress: emailInput });
    this.checkEmailDomain(emailInput);
  };

  handleEmailInput = (e) => {
    const emailInput = e.target.value;
    this.setState({
      emailAddress: emailInput,
      mailCheckSuggestion: '',
    });
  };

  handleBackClick = () => {
    this.setState({
      showAccountDeactivated: false,
    });
  };

  render() {
    const { header, modalCaption, queryStr, showDescription, showTitle, title, isCcpaRedirectUrl } = this.props;
    const { emailAddress, errorMsg, loading, mailCheckSuggestion, showAccountDeactivated } = this.state;
    const CustomHeader = header;
    let hpxStyle = null;
    let hpxErrorMessage = null;

    if (errorMsg) {
      hpxErrorMessage = errorMsg;
      hpxStyle = 'error';
    }

    let submitText = loading ? 'Loading...' : 'Continue';

    if (mailCheckSuggestion && !loading) {
      submitText = 'My email is correct.';
    }

    if (showAccountDeactivated) {
      return <AccountDeactivated showTitle={showTitle} onBackClick={this.handleBackClick} />;
    }

    return (
      <div className="PromptEmail">
        {CustomHeader ? (
          <CustomHeader />
        ) : (
          <Fragment>
            {showTitle && <StyledTitle size="lg">{title}</StyledTitle>}
            {showDescription && (
              <Text className="PromptEmail-description">
                {controller.getHeaderCaption({ modalCaption, queryStr, isCcpaRedirectUrl })}
              </Text>
            )}
          </Fragment>
        )}
        <Section>
          <form>
            <Row>
              <Input
                autoComplete="email"
                autoFocus={browserUtils.screen.width() && browserUtils.screen.width() !== 'sm'}
                className="login-input-email"
                help={hpxErrorMessage}
                hpxStyle={hpxStyle}
                id="email"
                label="Email"
                maxLength={115}
                name="email"
                onBlur={this.handleEmailInputBlur}
                onChange={this.handleEmailInput}
                required
                type="email"
                value={emailAddress}
              />
              {mailCheckSuggestion && (
                <div
                  onClick={() =>
                    this.setState({
                      mailCheckSuggestion: '',
                      emailAddress: mailCheckSuggestion,
                    })
                  }
                >
                  <StyledAlertText size="sm">
                    Did you mean <Linker>{mailCheckSuggestion}</Linker>?
                  </StyledAlertText>
                </div>
              )}
            </Row>
            <Row>
              <Button full onClick={this.handleSubmitEmail} disabled={loading}>
                {submitText}
              </Button>
            </Row>
          </form>
          <Divider />
          <div className="PromptEmail-social-container">
            <a
              className="PromptEmail-social-button PromptEmail-apple-login"
              aria-label="login-with-apple"
              href={appleAuth.getAuthorizationUrl()}
            >
              <Text className="PromptEmail-social-text" size="sm">
                <img className="PromptEmail-apple-icon" src={IconApple} alt="" /> Sign in with Apple
              </Text>
            </a>
            <button
              className="PromptEmail-social-button PromptEmail-facebook-login"
              onClick={this.handleFbLogin}
              aria-label="login-with-facebook"
            >
              <img className="PromptEmail-social-icon" src={IconFacebook} alt="" />
              <Text className="PromptEmail-social-text" size="sm">
                Sign in with Facebook
              </Text>
            </button>
            <div className=" PromptEmail-social-button" id="g_id_signin">
              {/* this button will be replaced with an iframe once sign in with google initializes */}
              <button className="PromptEmail-siwg-default-button" aria-label="sign-in-with-google" disabled>
                <img className="PromptEmail-social-icon" src={IconGoogle} alt="" />
                <div className="PromptEmail-social-text">Sign in with Google</div>
              </button>
            </div>
          </div>
          <Center>
            <StyledDarkText size="tiny">We value your privacy.</StyledDarkText>
          </Center>
          <Center>
            <StyledDarkText size="tiny">
              See our{' '}
              <Linker linkType="secondary" to={pathsManifest.ccpaPortal}>
                privacy portal
              </Linker>{' '}
              and{' '}
              <Linker linkType="secondary" to={pathsManifest.terms}>
                terms of use
              </Linker>
              .
            </StyledDarkText>
          </Center>
          <Center>
            <StyledDarkText size="tiny">
              <img src={IconLock} className="PromptEmail-lock-icon" alt="" /> Site secured by HTTPS security protocols.
            </StyledDarkText>
          </Center>
        </Section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const query = queryUtils.parse(ownProps.location.search);
  const isCcpaRedirectUrl =
    query.redirectUrl &&
    query.redirectUrl.includes(state.app.clusterSettingDict['node.ccpaRedirectUrl'] || pathsManifest.ccpaPortal);

  return {
    isCcpaRedirectUrl,
    isInitialSsrPage: state.app.isInitialSsrPage,
    modalCaption: state.app.popupModal.data.caption,
    queryStr: ownProps.location.search,
    countryCode: state.geolocation.area.countryCode,
  };
};

export default withRouter(connect(mapStateToProps)(PromptEmail));
